<template>
	<div class="half_layer_wrap">
		<div class="bg-dark" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; opacity: 0.5"></div>
		<div class="half_layer_wrap_inner" style="border-top: 1px solid #bbb">
			<article class="half_view">
				<div class="layer_title">
					<h3>MAFIA NFT CARD</h3>
				</div>
				<div class="utility_card">
					<div class="utility_card_area">
						<ul
							v-if="items.length > 0"
						>
							<li
								v-for="(item, index) in items"
								:key="'item_' + index"
								class="square"
							>
								<div class="inner">
									<button
										@click="updateMainNft(item)"
									>
										<img
											:src="$request.upload_url(item.nft_card_img_url)"
											:alt="item.nft_card_number"
										/>
										<!-- TODO : 아이템 장착카드시 표시 -->
										<span
											v-if="item.is_use"
											class="item_choice"
										><em class="hide">아이템장착</em></span>
									</button>
								</div>
							</li>
						</ul>
						<div
							v-else
							style="text-align: center; font-size: 14px"
						><img :src="require('@/assets/image/@card_none.png')" alt="빈슬롯" width="40%"> <br/> <br/> 등록된 NFT 카드가 없습니다</div>
					</div>
				</div>

			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia1011'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'NFT 카드'

				}
				, items: []
				, item_main_nft: {}
			}
		}
		, methods: {
			getMainNft: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_main_nft
						, data: {
							member_number: this.user.member_number
						}
						, type: true
					})
					if(result.success){
						this.item_main_nft = result.data
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, getData: async function(){
				try{
					const result = await this.$request.init({
						method: 'post'
						, url: this.$api_url.api_path.get_nft
						, data: {
							member_number: this.user.member_number,
							page_number: this.$language.base.page_number,
							pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})
					if(result.success){
						this.items = result.data.nft_card_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, updateMainNft: async function(item){

				this.$emit('click', item)
				/*
				try{
					this.$bus.$emit('on', true)
					const result = await this.$request.init({
						method: 'post'
						,url: this.$api_url.api_path.post_profile_set
						,data: {
							member_number: this.user.member_number
							, nft_card_number: item.nft_card_number
							, settup_fg: 'Y'
						}
						, type: true
					})

					if(result.success){
						this.$emit('click')
					}else{
						this.$bus.$emit('notify',  { type: 'error', message: result.message, config: { bottom: 0}})
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: this.$language.common.error, config: { bottom: 0}})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}

				 */
			}
		}
		, async created() {
			await this.getMainNft()
			await this.getData()
		}
	}
</script>